import saveAs from "file-saver"
import JSZip from "jszip"
import { PERSONAL_GUARANTY } from "src/statics"
import { ApplicationTemplate, Page, PDFFile } from "src/types"

export function getPersonalGuarantyFromSchema(page: any) {
  if (page.enabled === true && page.required === true) {
    return PERSONAL_GUARANTY.REQUIRED
  } else if (page.enabled === true && page.required === false) {
    return PERSONAL_GUARANTY.OPTIONAL
  } else if (page.enabled === false) {
    return PERSONAL_GUARANTY.OFF
  }
  return ""
}

export function isSectionEnabled(
  template: ApplicationTemplate | undefined,
  label: string,
) {
  return (
    template?.formTemplate?.pages.find((page) => page.label === label)
      ?.enabled === true
  )
}

export const isEmail = (value: string) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)

export default function stateNameToAbbreviation(name: string): string {
  const lowerCaseAbbreviationKey = name
    .trim()
    .replace(/[^\w ]/g, "")
    .toLowerCase() // Trim, lowercase and remove all non-word characters with the exception of spaces
  return states[lowerCaseAbbreviationKey] ?? name // Return name if record is not found
}

const states: Record<string, string> = {
  arizona: "AZ",
  alabama: "AL",
  alaska: "AK",
  arkansas: "AR",
  california: "CA",
  colorado: "CO",
  connecticut: "CT",
  "district of columbia": "DC",
  delaware: "DE",
  florida: "FL",
  georgia: "GA",
  hawaii: "HI",
  idaho: "ID",
  illinois: "IL",
  indiana: "IN",
  iowa: "IA",
  kansas: "KS",
  kentucky: "KY",
  louisiana: "LA",
  maine: "ME",
  maryland: "MD",
  massachusetts: "MA",
  michigan: "MI",
  minnesota: "MN",
  mississippi: "MS",
  missouri: "MO",
  montana: "MT",
  nebraska: "NE",
  nevada: "NV",
  "new hampshire": "NH",
  "new jersey": "NJ",
  "new mexico": "NM",
  "new york": "NY",
  "north carolina": "NC",
  "north dakota": "ND",
  ohio: "OH",
  oklahoma: "OK",
  oregon: "OR",
  pennsylvania: "PA",
  "rhode island": "RI",
  "south carolina": "SC",
  "south dakota": "SD",
  tennessee: "TN",
  texas: "TX",
  utah: "UT",
  vermont: "VT",
  virginia: "VA",
  washington: "WA",
  "west virginia": "WV",
  wisconsin: "WI",
  wyoming: "WY",
  "american samoa": "AS",
  guam: "GU",
  "northern mariana islands": "MP",
  "puerto rico": "PR",
  "us virgin islands": "VI",
  "us minor outlying islands": "UM",
}

export const convertArrayToString = (
  value:
    | { label: string; value: string }[]
    | string
    | { [key: string]: string }
    | undefined,
) => {
  if (value) {
    if (typeof value === "string") {
      return value
    }
    return JSON.stringify(value)
  }
  return ""
}

export const getPageFromTemplate = (
  applicationTemplate: ApplicationTemplate,
  sectionLabel: string,
) => {
  const section = applicationTemplate.formTemplate?.pages.filter(
    (p) => p.label == sectionLabel,
  )

  if (section.length > 0) {
    return section[0]
  }
  return undefined
}

export const getConfigFromPage = (page: Page, label: string) => {
  const config = page.config.filter((p) => p.label == label)
  if (config.length > 0) {
    return config[0]
  }
  return undefined
}

export const generateString = (length: number) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let result = " "
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const createZipFileOfPDFs = (
  folder_name: string,
  pdfFiles: PDFFile[],
) => {
  const zip = new JSZip()
  for (const pdf of pdfFiles) {
    let extension = ".pdf"
    if (pdf.extension){
      extension = pdf.extension
    }
    zip.file(pdf.name + extension, pdf.file as string, { base64: true })
  }
  zip
    .generateAsync({ type: "blob" })
    .then(function (content) {
      saveAs(content, folder_name + ".zip")
      return
    })
    .catch((error) => {
      return
    })
}
