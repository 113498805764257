import { useAuth0 } from "@auth0/auth0-react"
import axios, { AxiosError } from "axios"
import { QueryClient, QueryClientProvider } from "react-query"
import { MutationVariables } from "../types/global.types"
import { useSnackbar } from "notistack"
import Cookies from "js-cookie"
import { error } from "src/utils/logger"
import * as Sentry from "@sentry/react"

// for the functional component
type Props = {
  children: JSX.Element
}

/**
 * Goal of is component is to integrate the Auth0 token fetcher before every axios call.
 * To accomplish this, we override the default queryFn and mutationFn.
 * This also meant designing with ability to change the body and headers (especially for POST).
 * The mutationFn is strongly typed to `unknown`. To make the overriding possible, I introduced the MutationVariables type.
 */
export default ({ children }: Props) => {
  const { enqueueSnackbar } = useSnackbar()

  const { getAccessTokenSilently, getAccessTokenWithPopup, loginWithRedirect } =
    useAuth0()

  const audience = process.env.REACT_APP_AUTH0_AUDIENCE
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 20000,
  })
  const queryClient = new QueryClient({
    defaultOptions: {
      // GET
      queries: {
        queryFn: async ({ queryKey, meta }) => {
          try {
            if (meta?.anonymous) {
              const response = await instance.request({
                method: "get",
                url: queryKey[0] as string,
              })
              return response.data
            }
            const token = await getAccessTokenSilently({
              authorizationParams: { audience },
            }).catch(function (e) {
              Sentry.captureException(e)
              if (e.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                error(e.response)
              } else if (e.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                error(e.request)
              } else {
                // Something happened in setting up the request that triggered an Error
                // we will end up here if consent is required
                error("Error getting an auth token", e.message)
                // eslint-disable-next-line promise/no-nesting
                return getAccessTokenWithPopup({
                  authorizationParams: { audience },
                  // eslint-disable-next-line promise/no-nesting
                }).then(() => loginWithRedirect())
              }
            })
            const response = await instance.request({
              method: "get",
              url: queryKey[0] as string,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            return response.data
          } catch (e) {
            if (process.env.REACT_APP_ENV !== "production") {
              error("error fetching", e)
            }
            Sentry.captureException(e)
            if (e instanceof AxiosError) {
              let errorData = e.response?.data
              if (errorData?.error) {
                errorData = errorData.error
              } else if (errorData?.detail) {
                errorData = errorData.detail
              }

              enqueueSnackbar(errorData || e.message, {
                variant: "error",
                preventDuplicate: true,
              })
            } else if (e instanceof Error) {
              enqueueSnackbar(e.message, {
                variant: "error",
                preventDuplicate: true,
              })
            }

            return Promise.reject(e)
          }
        },
      },
      // POST, PUT, PATCH, DELETE
      mutations: {
        mutationFn: async (variables) => {
          try {
            if ((variables as MutationVariables<unknown>).anonymous) {
              const response = await instance.request({
                method:
                  (variables as MutationVariables<unknown>).method || "post",
                url: (variables as MutationVariables<unknown>).endpoint,
                data: (variables as MutationVariables<unknown>).body,
                headers: {
                  "X-CSRFToken": Cookies.get("csrftoken"),
                  // ammending extra headers passed to .mutate()
                  ...(variables as MutationVariables<unknown>).headers,
                },
              })
              return response.data
            }

            const token = await getAccessTokenSilently({
              authorizationParams: { audience },
            }).catch(function (e) {
              Sentry.captureException(e)
              if (e.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                error(e.response)
              } else if (e.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                error(e.request)
              } else {
                // Something happened in setting up the request that triggered an Error
                // we will end up here if consent is required
                error("Error getting an auth token", e.message)
                return getAccessTokenWithPopup({
                  authorizationParams: { audience },
                  // eslint-disable-next-line promise/no-nesting
                }).then(() => loginWithRedirect())
              }
            })
            const response = await instance.request({
              method:
                (variables as MutationVariables<unknown>).method || "post",
              url: (variables as MutationVariables<unknown>).endpoint,
              data: (variables as MutationVariables<unknown>).body,
              headers: {
                Authorization: `Bearer ${token}`,
                "X-CSRFToken": Cookies.get("csrftoken"),
                // ammending extra headers passed to .mutate()
                ...(variables as MutationVariables<unknown>).headers,
              },
            })
            return response.data
          } catch (e) {
            error("error fetching", e)
            Sentry.captureException(e)
            if (e instanceof AxiosError) {
              let errorData = e.response?.data
              if (errorData?.error) {
                errorData = errorData.error
              } else if (errorData?.detail) {
                errorData = errorData.detail
              }

              enqueueSnackbar(errorData || e.message, {
                variant: "error",
                preventDuplicate: true,
              })
            } else if (e instanceof Error) {
              enqueueSnackbar(e.message, {
                variant: "error",
                preventDuplicate: true,
              })
            }
            return Promise.reject(e)
          }
        },
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
