import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"

export function usePostRequestedCustomDocument(onSuccess?: () => void) {
  const ENDPOINT = "/application/base/request_custom_document"

  const post = useTypedMutation<{
    documentId: string
    applicationId: string
    email: string
    instructions?: string
  }>("post/application/base/request_custom_document", onSuccess)

  const execute = ({
    documentId,
    applicationId,
    email,
    instructions,
  }: {
    documentId: string
    applicationId: string
    email: string
    instructions?: string
  }) => {
    post.mutate(
      {
        method: "post",
        endpoint: ENDPOINT + "/",
        body: {
          documentId,
          applicationId,
          email,
          instructions,
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...post, execute }
}
