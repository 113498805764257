import { useState } from "react"
import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Stack,
  Grid,
  TextField,
  MenuItem,
  Select,
} from "@mui/material"
import { CreditApplication } from "src/types"
import { useSnackbar } from "notistack"
import { useCustomDocuments } from "src/queries/credit/useCustomDocuments"
import { usePostRequestedCustomDocument } from "src/queries/credit/usePostRequestedCustomDocument"

export default ({
  application,
  onClose,
  open,
}: {
  application: CreditApplication
  onClose: () => void
  open: boolean
}) => {
  const { data: documents } = useCustomDocuments()

  const { isLoading: isPosting, execute } = usePostRequestedCustomDocument(
    () => {
      enqueueSnackbar("Document request sent", { variant: "success" })
      onClose()
    },
  )

  const [recipient, setRecipient] = useState(application.data.email || "")

  const [doc, setDoc] = useState<string | undefined>(undefined)

  const { enqueueSnackbar } = useSnackbar()

  const [instructions, setInstructions] = useState("")

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
      <DialogTitle>Request Custom Document</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Grid container>
            <Grid item md={12}>
              <Typography style={{ marginBottom: "1rem" }}>
                Select the document you would like to request:
              </Typography>
              <Select
                labelId="document-select"
                id="document-select"
                value={doc}
                fullWidth
                onChange={(e) => {
                  setDoc(e.target.value)
                }}
              >
                {documents?.map((doc) => {
                  return (
                    <MenuItem value={doc.id} key={doc.id}>
                      {doc.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
          </Grid>
          {doc && (
            <>
              <Grid container>
                <Grid item md={4}>
                  <Typography>Recipient Email *</Typography>
                </Grid>
                <Grid item md={8}>
                  <Select
                    labelId="recipient-select"
                    id="recipient-select"
                    value={recipient}
                    fullWidth
                    onChange={(e) => {
                      setRecipient(e.target.value)
                    }}
                  >
                    {typeof application.collaborators === "string"
                      ? application.collaborators?.split(",")?.map((email) => {
                          return (
                            <MenuItem value={email} key={email}>
                              {email}
                            </MenuItem>
                          )
                        })
                      : application.collaborators?.map((email) => {
                          return (
                            <MenuItem value={email} key={email}>
                              {email}
                            </MenuItem>
                          )
                        })}
                  </Select>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={12}>
                  <Typography style={{ marginBottom: "1rem" }}>
                    Recipient Instructions
                  </Typography>
                  <TextField
                    type="text"
                    fullWidth
                    multiline
                    placeholder="Enter any additional instructions for the applicant here. This will be included in the email."
                    value={instructions}
                    onChange={(e) => {
                      setInstructions(e.target.value)
                    }}
                  />
                </Grid>
              </Grid>

              <Typography>
                We will send an email to <b>{recipient}</b> with a link to
                complete <b>{documents?.find((d) => d.id === doc)?.name}</b>.
                You will receive a notification when completed.
              </Typography>
            </>
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          disabled={!recipient || !doc}
          variant="contained"
          loading={isPosting}
          onClick={() => {
            if (doc && application.id) {
              execute({
                documentId: doc,
                applicationId: application.id,
                email: recipient,
                instructions,
              })
            }
          }}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
