import { useState } from "react"
import { ArrowForward } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"

import {
  Box,
  Checkbox,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material"
import DebouncedAutocomplete from "src/components/autocomplete/DebouncedAutocomplete"
import { useBusinessSearch } from "src/queries/vendors/useBusinessSearch"
import { ApplicationTemplate, Application } from "src/types"

import Address from "../components/Address"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import { useSalesReps } from "src/queries/base/useSalesReps"
import { useCreditManagers } from "src/queries/base/useCreditManagers"
import {
  BUSINESS_CONFIG_BILLING_ADDRESS,
  BUSINESS_CONFIG_BUSINESS_ADDRESS,
  BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
  BUSINESS_CONFIG_SERVICE_ADDRESS,
  BUSINESS_CONFIG_SHIPPING_ADDRESS,
  FORM_INPUT_NAMES,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_PROJECT_DETAILS,
} from "./constants"
import {
  APPLICANT_TYPES,
  APPLICANT_TYPES_OPTIONS_REQUIRES_BUSINESS_NAME,
  customWordMapping,
} from "src/statics"
import {
  getAddressTypes,
  getCreditManagerSelectorEnabled,
  getRevenueEnabled,
} from "./schemas/CompanyDetailsSectionSchema"
import { isPersonalApplication } from "../utils"

type Props = {
  application?: Application
  data: Application["data"]
  errors: Application["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onCustomChange: (key: string, value: any) => void
  onContinue: () => void
  onApplicationFieldUpdated?: (key: string, value: any) => void
  topLevelValues?: Application
  template?: ApplicationTemplate
}

export default ({
  application,
  data,
  errors,
  onChange,
  onCustomChange,
  onContinue,
  onApplicationFieldUpdated,
  topLevelValues,
  template,
}: Props) => {
  const [hideShippingAddress, setHideShippingAddress] = useState(false)
  const [hideBillingAddress, setHideBillingAddress] = useState(false)

  const [hideCcBillingAddress, setHideCcBillingAddress] = useState(false)

  const [hideDba, setHideDba] = useState(false)

  const { data: salesReps } = useSalesReps(application?.seller?.id || "")

  const { data: creditManagers } = useCreditManagers(
    application?.seller?.id || "",
  )

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        {isPersonalApplication(data)
          ? PAGE_LABEL_PROJECT_DETAILS
          : PAGE_LABEL_BUSINESS_DETAILS}
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        {isPersonalApplication(data)
          ? "Let us know about your project and where it is located."
          : "Let us know about your business and where it is located."}
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />
      {APPLICANT_TYPES_OPTIONS_REQUIRES_BUSINESS_NAME.includes(
        data.applicantType,
      ) && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <DebouncedAutocomplete
              useSuggestions={useBusinessSearch}
              style={{ width: "100%" }}
              label="Legal Business Name"
              required
              inputProps={{
                id: "legal-business-name",
                style: { height: "56px" },
              }}
              value={data.legalBusinessName}
              onChange={(val) => {
                onChange(FORM_INPUT_NAMES.LEGAL_BUSINESS_NAME, val)
                if (hideDba) {
                  onChange(FORM_INPUT_NAMES.BUSINESS_DBA, val)
                }
              }}
              error={Boolean(errors?.legalBusinessName)}
            />
            {!hideDba && (
              <TextField
                id="business-dba"
                margin="normal"
                fullWidth
                label="Doing Business As / Trade Name"
                type="text"
                value={data.businessDba}
                onChange={(event) => {
                  onChange(FORM_INPUT_NAMES.BUSINESS_DBA, event.target.value)
                }}
                inputProps={{
                  style: { height: "56px" },
                }}
                error={Boolean(errors?.businessDba)}
              />
            )}
          </Box>
          <FormGroup>
            <FormControlLabel
              defaultChecked={true}
              control={
                <Checkbox
                  id="dba-same"
                  onChange={(event) => {
                    setHideDba(event.target.checked)
                    if (event.target.checked) {
                      onChange(
                        FORM_INPUT_NAMES.BUSINESS_DBA,
                        data.legalBusinessName,
                      )
                    } else {
                      onChange(FORM_INPUT_NAMES.BUSINESS_DBA, undefined)
                    }
                  }}
                />
              }
              label="DBA same as legal business name"
            />
          </FormGroup>
        </>
      )}
      {getAddressTypes(template).includes(BUSINESS_CONFIG_BUSINESS_ADDRESS) && (
        <>
          <Typography variant="subtitle1">Mailing Address</Typography>

          <Address
            keyPrefix="business"
            data={data}
            onChange={onChange}
            errors={errors}
            defaults={{ country: application?.seller?.country || "" }}
          />
        </>
      )}

      {getAddressTypes(template).includes(BUSINESS_CONFIG_BILLING_ADDRESS) && (
        <>
          <Typography variant="subtitle1">Billing Address</Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="billing-same-as-mailing"
                  onChange={(event) => {
                    setHideBillingAddress(event.target.checked)
                    if (event.target.checked) {
                      onChange("billingAddress", data.businessAddress)
                      onChange("billingCity", data.businessCity)
                      onChange("billingCountry", data.businessCountry)
                      onChange("billingRegion", data.businessRegion)
                      onChange("billingPostCode", data.businessPostCode)
                    } else {
                      onChange("billingAddress", undefined)
                      onChange("billingCity", undefined)
                      onChange("billingCountry", undefined)
                      onChange("billingRegion", undefined)
                      onChange("billingPostCode", undefined)
                    }
                  }}
                />
              }
              label="Billing address same as mailing address"
            />
          </FormGroup>

          {!hideBillingAddress && (
            <Address
              keyPrefix="billing"
              data={data}
              onChange={onChange}
              errors={errors}
              defaults={{ country: application?.seller?.country || "" }}
            />
          )}
        </>
      )}

      {getAddressTypes(template).includes(BUSINESS_CONFIG_SHIPPING_ADDRESS) && (
        <>
          <Typography variant="subtitle1">
            {customWordMapping("Shipping Address", template)}
          </Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="shipping-same-as-mailing"
                  onChange={(event) => {
                    setHideShippingAddress(event.target.checked)
                    if (event.target.checked) {
                      onChange(
                        FORM_INPUT_NAMES.SHIPPING_ADDRESS,
                        data.businessAddress,
                      )
                      onChange(
                        FORM_INPUT_NAMES.SHIPPING_CITY,
                        data.businessCity,
                      )
                      onChange(
                        FORM_INPUT_NAMES.SHIPPING_COUNTRY,
                        data.businessCountry,
                      )
                      onChange(
                        FORM_INPUT_NAMES.SHIPPING_REGION,
                        data.businessRegion,
                      )
                      onChange(
                        FORM_INPUT_NAMES.SHIPPING_POST_CODE,
                        data.businessPostCode,
                      )
                    } else {
                      onChange(FORM_INPUT_NAMES.SHIPPING_ADDRESS, undefined)
                      onChange(FORM_INPUT_NAMES.SHIPPING_CITY, undefined)
                      onChange(FORM_INPUT_NAMES.SHIPPING_COUNTRY, undefined)
                      onChange(FORM_INPUT_NAMES.SHIPPING_REGION, undefined)
                      onChange(FORM_INPUT_NAMES.SHIPPING_POST_CODE, undefined)
                    }
                  }}
                />
              }
              label={`${customWordMapping(
                "Shipping address",
                template,
              )} same as mailing address`}
            />
          </FormGroup>

          {!hideShippingAddress && (
            <Address
              keyPrefix="shipping"
              data={data}
              onChange={onChange}
              errors={errors}
              defaults={{ country: application?.seller?.country || "" }}
            />
          )}
        </>
      )}

      {getAddressTypes(template).includes(BUSINESS_CONFIG_SERVICE_ADDRESS) && (
        <>
          <Typography variant="subtitle1">Service Address</Typography>

          <Address
            keyPrefix="service"
            data={data}
            onChange={onChange}
            errors={errors}
            defaults={{ country: application?.seller?.country || "" }}
          />
        </>
      )}

      {getAddressTypes(template).includes(
        BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
      ) && (
        <>
          <Typography variant="subtitle1">
            Credit Card Billing Address
          </Typography>

          {/* <FormGroup>
            <FormControlLabel
              required
              control={
                <Checkbox
                  id="cc-billing-same-as-mailing"
                  onChange={(event) => {
                    setHideCcBillingAddress(event.target.checked)
                    if (event.target.checked) {
                      onChange("creditCardBillingAddress", data.businessAddress)
                      onChange("creditCardBillingCity", data.businessCity)
                      onChange("creditCardBillingCountry", data.businessCountry)
                      onChange("creditCardBillingRegion", data.businessRegion)
                      onChange(
                        "creditCardBillingPostCode",
                        data.businessPostCode,
                      )
                    } else {
                      onChange("creditCardBillingAddress", undefined)
                      onChange("creditCardBillingCity", undefined)
                      onChange("creditCardBillingCountry", undefined)
                      onChange("creditCardBillingRegion", undefined)
                      onChange("creditCardBillingPostCode", undefined)
                    }
                  }}
                />
              }
              label="Credit Card billing address same as mailing address"
            />
          </FormGroup> */}

          {!hideCcBillingAddress && (
            <Address
              keyPrefix="creditCardBilling"
              data={data}
              onChange={onChange}
              errors={errors}
              defaults={{ country: application?.seller?.country || "" }}
            />
          )}
        </>
      )}

      {![APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER].includes(
        data.applicantType,
      ) &&
        getRevenueEnabled(template) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ flex: "1 1 0px" }}>
              What is the annual revenue of your business?
            </Typography>
            <FormControl
              fullWidth
              margin="normal"
              style={{ flex: "1 1 0px" }}
              required
            >
              <InputLabel id="revenue-label">Revenue</InputLabel>
              <Select
                labelId="revenue-label"
                id="revenue-select"
                label="Revenue"
                value={data.revenue}
                onChange={(event) => {
                  onChange(FORM_INPUT_NAMES.REVENUE, event.target.value)
                }}
                style={{ height: "56px" }}
                error={Boolean(errors?.revenue)}
              >
                <MenuItem disabled value={undefined}>
                  Select one
                </MenuItem>
                {[
                  "Less than $100,000 / year",
                  "$100,000 - $500,000 / year",
                  "$500,000 - $1M / year",
                  "$1M - $2.5M / year",
                  "$2.5M - $10M / year",
                  "$10M - $50M / year",
                  "More than $50M / year",
                  "Not a business",
                ].map((revenue) => (
                  <MenuItem value={revenue} key={revenue}>
                    {revenue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      {onApplicationFieldUpdated !== undefined &&
        topLevelValues !== undefined &&
        salesReps &&
        salesReps.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ flex: "1 1 0px" }}>
              Who is the Sales Representative associated with this account?
            </Typography>
            <FormControl fullWidth margin="normal" style={{ flex: "1 1 0px" }}>
              <InputLabel id="sales-rep-label">Sales Rep</InputLabel>
              <Select
                labelId="sales-rep-label"
                id="sales-rep-select"
                label="Sales Rep"
                value={topLevelValues?.salesRep?.id}
                onChange={(event) => {
                  onApplicationFieldUpdated(
                    FORM_INPUT_NAMES.SALES_REP,
                    salesReps?.find(
                      (salesRep) => salesRep.id === event.target.value,
                    ) || { id: -1 },
                  )
                }}
                error={Boolean(errors?.salesRep)}
                style={{ height: "56px" }}
              >
                <MenuItem disabled value={undefined}>
                  Select One
                </MenuItem>
                {salesReps
                  ?.sort((a, b) => {
                    if (!a.firstName) {
                      return 1
                    }
                    if (!b.firstName) {
                      return -1
                    }
                    return a.firstName < b.firstName ? -1 : 1
                  })
                  ?.map((salesRep) => (
                    <MenuItem value={salesRep.id} key={salesRep.id}>
                      {salesRep.firstName} {salesRep.lastName}
                    </MenuItem>
                  ))}
                <MenuItem value={-1} key="sales_rep_0">
                  No Sales Rep / Other
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      {onApplicationFieldUpdated !== undefined &&
        topLevelValues !== undefined &&
        getCreditManagerSelectorEnabled(template) &&
        creditManagers && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ flex: "1 1 0px" }}>
              Who is the Credit Manager associated with this account?
            </Typography>
            <FormControl fullWidth margin="normal" style={{ flex: "1 1 0px" }}>
              <InputLabel id="credit-manager-label">Credit Manager</InputLabel>
              <Select
                labelId="credit-manager-label"
                id="credit-manager-select"
                label="Credit Manager"
                value={topLevelValues?.assignee?.id}
                onChange={(event) => {
                  onApplicationFieldUpdated(
                    FORM_INPUT_NAMES.ASSIGNEE,
                    creditManagers?.find(
                      (creditManager) =>
                        creditManager.id === event.target.value,
                    ) || { id: -1 },
                  )
                }}
                error={Boolean(errors?.creditManager)}
                style={{ height: "56px" }}
              >
                <MenuItem disabled value={undefined}>
                  Select One
                </MenuItem>

                {creditManagers?.map((creditManager) => (
                  <MenuItem value={creditManager.id} key={creditManager.id}>
                    {creditManager.firstName} {creditManager.lastName}
                  </MenuItem>
                ))}
                <MenuItem value={-1} key="sales_rep_0">
                  Unknown / Other
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

      {template?.customFields && (
        <ReusableCustomQuestions
          application={application}
          associatedPage={"Business Details"}
          data={data["customFields"]}
          dataKeyResponses={data}
          errors={errors}
          onChange={onCustomChange}
        />
      )}

      <LoadingButton
        id="continue-business"
        onClick={() => {
          if (hideShippingAddress) {
            // the user can change the address after checking the box.
            // in that case, the changes don't propagate. and we should sync here.
            onChange("shippingAddress", data.businessAddress)
            onChange("shippingCity", data.businessCity)
            onChange("shippingCountry", data.businessCountry)
            onChange("shippingRegion", data.businessRegion)
            onChange("shippingPostCode", data.businessPostCode)
          }
          if (hideBillingAddress) {
            // the user can change the address after checking the box.
            // in that case, the changes don't propagate. and we should sync here.
            onChange("billingAddress", data.businessAddress)
            onChange("billingCity", data.businessCity)
            onChange("billingCountry", data.businessCountry)
            onChange("billingRegion", data.businessRegion)
            onChange("billingPostCode", data.businessPostCode)
          }
          onContinue()
        }}
        size="large"
        fullWidth
        endIcon={<ArrowForward />}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Save & Continue
      </LoadingButton>
    </>
  )
}
