// @mui
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"

import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tooltip,
  Chip,
  Slide,
  Paper,
} from "@mui/material"

import PDFOrImageViewerDialog from "src/components/pdf/PDFOrImageViewerDialog"
import { useState } from "react"
import TradeReferencesCard from "./components/TradeReferencesCard"

import { useContractorSearch } from "src/queries/base/useContractorSearch"

import { camelCase } from "lodash"

// ----------------------------------------------------------------------

import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import TwoColumnEditableCardSection from "../components/TwoColumnEditableCardSection"
import { LoadingButton } from "@mui/lab"
import { UploadedFile } from "src/types"
import { useTradeReferences } from "src/queries/credit/useTradeReferences"
import UploadedCreditReport from "./UploadedCreditReport"
import { usePatchApplication } from "src/queries/credit/usePatchApplication"
import { useDeleteUploadedFile } from "src/queries/credit/useDeleteUploadedFile"

import { DropzoneDialog } from "mui-file-dropzone"
import { usePostUploadedFile } from "src/queries/credit/usePostUploadedFile"
import { useSnackbar } from "notistack"
import CreditApplicationDetailsPersonalGuaranty from "./credit-application-details-personal-guaranty"
import CreditApplicationBankReference from "./credit-application-bank-reference"
import CreditApplicationBusinessRegistries from "./credit-application-business-registries"

import FileItem from "src/sections/@dashboard/components/file/FileItem"
import { confirm } from "src/components/confirm"
import { info } from "src/utils/logger"
import { isFileValid } from "src/utils/file-validator"
import {
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_OWNERS,
  PAGE_LABEL_USER_DETAILS,
  PAGE_LABEL_ADDITIONAL_QUESTIONS,
  PAGE_LABEL_TRADE_REFERENCES,
  PAGE_LABEL_PERSONAL_GUARANTY,
} from "../intake_sections/constants"
import SkippedMessage from "../components/SkippedMessage"

import { useEmailStatus } from "src/queries/vendors/useEmailStatus"
import { getBusinessTypes, renderCustomQuestionsFor } from "../utils"
import { APPLICANT_TYPES_OPTIONS, ROLES } from "src/statics"
import { COUNTRIES } from "../components/Address"
import AddSignerDialog from "./components/AddSignerDialog"
import {
  TravelExploreOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material"
import { useValidateDomain } from "src/queries/vendors/useValidateDomain"
import { useRequestedCustomDocument } from "src/queries/credit/useRequestedCustomDocument"
import CustomDocumentItem from "../components/file/CustomDocumentItem"

export default function ({
  applicationId,
  setOpenCompanyDataDialogs,
  tab,
}: {
  applicationId: string
  setOpenCompanyDataDialogs: (value: boolean) => void
  tab: number
}) {
  const { data: application, refetch: refetchApplication } =
    useCreditApplication(applicationId)
  const { execute: patchApplication, isLoading: isPatching } =
    usePatchApplication(() => {
      refetchApplication()
      enqueueSnackbar("Application updated", { variant: "success" })
    })

  const { execute: deleteFile } = useDeleteUploadedFile(() => {
    refetchApplication()
    enqueueSnackbar("Application updated", { variant: "success" })
  })

  const { data: businessTemplate } = useApplicationTemplate()

  const templateData = application?.template || businessTemplate?.formTemplate

  let addressTypes = templateData?.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.filter((config) => config.label.includes("Address"))
    ?.filter((c) => c.value === true)
    ?.map((c) => c.label)

  if (!addressTypes || addressTypes.length === 0) {
    addressTypes = ["Business Address", "Shipping Address"]
  }

  const [contractor, setContractor] = useState("")

  const { data: tradeRefs } = useTradeReferences(applicationId)

  const [addressType, setAddressType] = useState<string | null>("business")
  const [ownerToggle, setOwnerToggle] = useState<string | null>("1")
  const [apToggle, setApToggle] = useState<string | null>("1")

  const { execute: uploadFiles } = usePostUploadedFile()
  const [uploadFileToggle, setUploadFileToggle] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const {} = useEmailStatus(tradeRefs?.map((r) => r.email) || [], applicationId)

  const { data: domainData, isFree } = useValidateDomain(
    application?.data?.email?.includes("@") &&
      application?.data?.email?.split("@").length > 1
      ? application?.data?.email?.split("@")[1]
      : "",
  )

  const handleAddressTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: string | null,
  ) => {
    if (newType) {
      setAddressType(newType)
    }
  }

  const {
    data: contractorData,
    refetch,
    isLoading,
  } = useContractorSearch(contractor)

  const [open, setOpen] = useState(false)

  const [addSignerDialog, setAddSignerDialog] = useState(false)

  const { data: requestedCustomDocuments } = useRequestedCustomDocument(
    undefined,
    application?.id,
  )
  const [viewDocument, setViewDocument] = useState<string | undefined>(
    undefined,
  )

  if (!application) {
    return <div>Loading...</div>
  }

  const renderUser = () => {
    const numberOfCollaborators =
      typeof application.collaborators === "string"
        ? application.collaborators.split(",").length
        : application.collaborators?.length || 2

    return (
      <>
        <TwoColumnEditableCardSection
          actions={
            <Button
              onClick={() => {
                setAddSignerDialog(true)
              }}
            >
              Add Signer
            </Button>
          }
          title={
            <Box
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              Submitted By
              {numberOfCollaborators > 1 && (
                <Tooltip
                  title={
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      {typeof application.collaborators === "string"
                        ? application.collaborators
                            ?.split(",")
                            ?.map((email) => {
                              return (
                                <Typography key={email}>
                                  {email}
                                  {email !== application.data?.email
                                    ? " (Signer)"
                                    : ""}
                                </Typography>
                              )
                            })
                        : application.collaborators?.map((email) => {
                            return (
                              <Typography key={email}>
                                {email}
                                {email !== application.data?.email
                                  ? " (Signer)"
                                  : ""}
                              </Typography>
                            )
                          })}
                      <Typography variant="caption">
                        Collaborators are invited by the submitter and could be
                        people who have authorization to sign the credit
                        agreement or have access to banking information.
                      </Typography>
                    </Box>
                  }
                  placement="bottom"
                  arrow
                >
                  <Chip
                    label={`+ ${numberOfCollaborators - 1} Collaborator(s)`}
                    color="info"
                    variant="outlined"
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              )}
              {isFree && (
                <Tooltip
                  title={
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography>Not a business email</Typography>
                    </Box>
                  }
                  placement="bottom"
                  arrow
                >
                  <WarningAmberOutlined htmlColor="orange" />
                </Tooltip>
              )}
              {domainData && (
                <Tooltip
                  title={
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography>Domain Verification</Typography>
                      <Typography variant="caption">
                        Domain Is Registered: {domainData?.domainRegistered}
                      </Typography>
                      <Typography variant="caption">
                        Domain Creation Date: {domainData?.domainCreationDate}
                      </Typography>
                      <Typography variant="caption">
                        Domain Owner: {domainData?.ownerOrganization}
                      </Typography>
                      <Typography variant="caption">
                        Owner Country: {domainData?.ownerCountry}
                      </Typography>
                      <Typography variant="caption">
                        Owner State/Province: {domainData?.ownerStateProvince}
                      </Typography>
                    </Box>
                  }
                  placement="bottom"
                  arrow
                >
                  <TravelExploreOutlined color="primary" />
                </Tooltip>
              )}
            </Box>
          }
          data={application.data}
          applicationId={applicationId}
          patchApplication={patchApplication}
          isPatching={isPatching}
          rows={[
            [
              { name: "First Name", key: "firstName" },
              { name: "Last Name", key: "lastName" },
              { name: "Email", key: "email" },
              { name: "Phone", key: "userPhoneNumber" },
              { name: "Role", key: "role", options: ROLES },
              { name: "Role (if other)", key: "roleOther" },
            ],
            [
              {
                name: "Applicant Type",
                key: "applicantType",
                options: APPLICANT_TYPES_OPTIONS,
              },
              {
                name: "Business Type",
                key: "businessType",
                options: businessTemplate
                  ? getBusinessTypes(businessTemplate).concat(["Other"])
                  : [],
              },
              { name: "Business Type (if other)", key: "businessTypeOther" },
              { name: "Legal Name", key: "legalBusinessName" },
              { name: "DBA", key: "businessDba" },
              { name: "Annual Revenue", key: "revenue" },
            ],
          ]}
        />
        <Stack
          sx={{ typography: "body2" }}
          style={{ margin: "0 1.5rem 0 1.5rem" }}
        >
          {renderCustomQuestionsFor(
            businessTemplate,
            application,
            PAGE_LABEL_USER_DETAILS,
            () => {
              setOpen(true)
            },
            (file) => {
              setViewDocument(file)
            },
          )}
          {renderCustomQuestionsFor(
            businessTemplate,
            application,
            PAGE_LABEL_BUSINESS_DETAILS,
            () => {
              setOpen(true)
            },
            (file) => {
              setViewDocument(file)
            },
          )}
        </Stack>
      </>
    )
  }

  const renderAddress = (
    <TwoColumnEditableCardSection
      title="Address"
      subheader={
        <ToggleButtonGroup
          value={addressType}
          exclusive
          onChange={handleAddressTypeChange}
          size="small"
        >
          {addressTypes.map((type, i) => (
            <ToggleButton
              key={"addressSelector" + i}
              value={camelCase(type.replace(" Address", ""))}
            >
              {type.replace("Business", "Mailing")}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      }
      data={application.data}
      patchApplication={patchApplication}
      isPatching={isPatching}
      applicationId={applicationId}
      rows={[
        [
          { name: "Address Line 1", key: addressType + "Address" },
          { name: "Address Line 2", key: addressType + "AddressLine2" },
          { name: "City", key: addressType + "City" },
        ],
        [
          { name: "State/Province", key: addressType + "Region" },
          { name: "Country", key: addressType + "Country", options: COUNTRIES },
          { name: "Zip/Postal Code", key: addressType + "PostCode" },
        ],
      ]}
    />
  )

  const renderFiles = (
    <>
      <CardHeader
        title={
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Typography variant="h6">Attached Files</Typography>
            <Button
              variant="contained"
              onClick={() => {
                setUploadFileToggle(true)
              }}
            >
              Upload File
            </Button>
          </Box>
        }
      />
      <Stack
        sx={{ typography: "body2" }}
        style={{ margin: "1.5rem 1.5rem 1.5rem 1.5rem" }}
      >
        {application.files?.length === 0 && "No files found"}
        <Stack direction="column" justifyContent="space-evenly" spacing={2}>
          {(application.files as UploadedFile[])?.map((file, index) => (
            <FileItem
              fileName={file.name}
              key={index}
              file={file.file}
              fileSize={file.fileSize}
              onDelete={() => {
                confirm("You are about to delete this file.", {
                  okColor: "error",
                  okLabel: "Delete",
                })
                  .then(
                    () => {
                      return deleteFile(file.id)
                    },
                    () => {
                      info("cancelled")
                    },
                  )
                  .catch(() => {
                    info("cancelled")
                  })
              }}
              onDownload={() => {
                window.open(file.file, "_blank")
              }}
              onView={() => {
                setViewDocument(file.id)
              }}
            />
          ))}
        </Stack>
      </Stack>
      <CardHeader
        title={
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Typography variant="h6">Requested Custom Documents</Typography>
          </Box>
        }
      />
      <Stack
        sx={{ typography: "body2" }}
        style={{ margin: "1.5rem 1.5rem 1.5rem 1.5rem" }}
      >
        {requestedCustomDocuments?.length === 0 && (
          <Typography>
            No pending requests found. To request a custom document, click on{" "}
            <i>Request From Applicant</i> in the top right corner and select{" "}
            <i>Custom Document.</i>
          </Typography>
        )}
        <Stack direction="column" justifyContent="space-evenly" spacing={2}>
          {requestedCustomDocuments?.map((req, index) => (
            <CustomDocumentItem
              disableUpload
              key={index}
              onDownload={() => {
                window.open(req.file, "_blank")
              }}
              onUpload={function (): void {
                throw new Error("Function not implemented.")
              }}
              item={req}
            />
          ))}
        </Stack>
      </Stack>
      <DropzoneDialog
        open={uploadFileToggle}
        onSave={(newFiles) => {
          if (newFiles.length > 0) {
            const formData = new FormData()
            let hasValidationError = false
            newFiles.forEach((file, index) => {
              hasValidationError = !isFileValid(file)
              if (hasValidationError) {
                return enqueueSnackbar(
                  "Invalid file type for file " + file.name,
                  {
                    variant: "error",
                  },
                )
              }
              formData.append("file" + index, file)
            })
            if (!hasValidationError) {
              formData.append("applicationId", application.id || "")
              uploadFiles(formData, () => {
                refetchApplication()
                setUploadFileToggle(false)
                enqueueSnackbar("File uploaded", { variant: "success" })
              })
            } else {
              setUploadFileToggle(false)
            }
          }
        }}
        acceptedFiles={[
          "application/pdf",
          "image/png",
          "image/jpeg",
          "application/vnd.ms-outlook",
          "",
        ]}
        showPreviews={true}
        maxFileSize={31457280}
        onClose={() => {
          setUploadFileToggle(false)
        }}
        filesLimit={10}
        fileObjects={null}
        dropzoneText={
          "Drag and drop a PDF, PNG, MSG or JPEG file here or click to select a file"
        }
      />
    </>
  )

  const renderOwners = (
    <>
      <TwoColumnEditableCardSection
        title="Owners"
        subheader={
          <ToggleButtonGroup
            value={ownerToggle}
            exclusive
            onChange={(event, newOwner) => {
              if (newOwner) {
                setOwnerToggle(newOwner)
              }
            }}
            size="small"
          >
            <ToggleButton value="1">Owner 1</ToggleButton>
            {application.data.ownerName2 && (
              <ToggleButton value="2">Owner 2</ToggleButton>
            )}
          </ToggleButtonGroup>
        }
        data={application.data}
        applicationId={applicationId}
        patchApplication={patchApplication}
        isPatching={isPatching}
        rows={[
          [
            { name: "Name", key: "ownerName" + ownerToggle },
            { name: "Email", key: "ownerEmail" + ownerToggle },
            { name: "Owner Title", key: "ownerTitle" + ownerToggle },
            {
              name: "Drive License #",
              key: "ownerDriverLicense" + ownerToggle,
            },
            { name: "SSN / SIN", key: "ownerSsn" + ownerToggle },
            { name: "Role", key: "ownerRole" + ownerToggle },
            { name: "Date of Birth", key: "ownerDob" + ownerToggle },
            { name: "Phone Number", key: "ownerPhoneNumber" + ownerToggle },
          ],
          [
            {
              name: "Owner Percentage",
              key: "ownerPercentage" + ownerToggle,
              postFix: "%",
            },
            { name: "Address Line 1", key: "ownerAddress" + ownerToggle },
            { name: "Address Line 2", key: "ownerAddressLine2" + ownerToggle },
            { name: "City", key: "ownerCity" + ownerToggle },
            { name: "Region", key: "ownerRegion" + ownerToggle },
            { name: "Country", key: "ownerCountry" + ownerToggle },
            { name: "Zip/Postal Code", key: "ownerPostCode" + ownerToggle },
          ],
        ]}
      />
    </>
  )

  const renderContacts = (
    <TwoColumnEditableCardSection
      title="Contacts"
      data={application.data}
      applicationId={applicationId}
      patchApplication={patchApplication}
      isPatching={isPatching}
      subheader={
        <ToggleButtonGroup
          value={apToggle}
          exclusive
          onChange={(event, newAp) => {
            if (newAp) {
              setApToggle(newAp)
            }
          }}
          size="small"
        >
          <ToggleButton value="1">Credit/AP 1</ToggleButton>
          {application.data.apEmail2 && (
            <ToggleButton value="2">Credit/AP 2</ToggleButton>
          )}
        </ToggleButtonGroup>
      }
      rows={[
        [
          { name: "Name", key: "apName" + (apToggle === "1" ? "" : apToggle) },
          {
            name: "Role",
            key: "apPosition" + (apToggle === "1" ? "" : apToggle),
          },
        ],
        [
          {
            name: "Email",
            key: "apEmail" + (apToggle === "1" ? "" : apToggle),
          },
          {
            name: "Phone",
            key: "apPhoneNumber" + (apToggle === "1" ? "" : apToggle),
          },
        ],
      ]}
    />
  )

  const renderController = (
    <TwoColumnEditableCardSection
      title="Controller"
      data={application.data}
      applicationId={applicationId}
      patchApplication={patchApplication}
      isPatching={isPatching}
      rows={[
        [
          { name: "Name", key: "controllerName" },
          { name: "Role", key: "controllerPosition" },
        ],
        [
          { name: "Email", key: "controllerEmail" },
          { name: "Phone", key: "controllerPhoneNumber" },
        ],
      ]}
    />
  )

  const renderCustom = (
    <>
      <CardHeader title={PAGE_LABEL_ADDITIONAL_QUESTIONS} />
      <Stack sx={{ m: 3, typography: "body2" }}>
        {renderCustomQuestionsFor(
          businessTemplate,
          application,
          PAGE_LABEL_ADDITIONAL_QUESTIONS,
          () => {
            setOpen(true)
          },
          (file) => {
            setViewDocument(file)
          },
        )}
        {!application.data.customFields && "N/A"}
      </Stack>
    </>
  )
  return (
    <Card>
      <>
        <Slide
          direction="right"
          in={tab === 0}
          mountOnEnter
          unmountOnExit
          exit={false}
        >
          <Paper>
            {renderUser()}

            <Divider sx={{ borderStyle: "dashed" }} />

            {renderAddress}
            <Divider sx={{ borderStyle: "dashed" }} />

            {renderCustom}
          </Paper>
        </Slide>
        <Slide
          direction="right"
          in={tab === 1}
          mountOnEnter
          unmountOnExit
          exit={false}
        >
          <Paper>
            <SkippedMessage
              label={PAGE_LABEL_OWNERS}
              application={application}
            />

            {renderOwners}

            <Divider sx={{ borderStyle: "dashed" }} />

            {renderContacts}

            <Divider sx={{ borderStyle: "dashed" }} />

            {renderController}

            <Stack
              sx={{ typography: "body2" }}
              style={{ margin: "0 1.5rem 0 1.5rem" }}
            >
              {renderCustomQuestionsFor(
                businessTemplate,
                application,
                PAGE_LABEL_OWNERS,
                () => {
                  setOpen(true)
                },
                (file) => {
                  setViewDocument(file)
                },
              )}
            </Stack>
          </Paper>
        </Slide>
        <Slide
          direction="right"
          in={tab === 2}
          mountOnEnter
          unmountOnExit
          exit={false}
        >
          <Paper>
            <SkippedMessage
              label={PAGE_LABEL_TRADE_REFERENCES}
              application={application}
            />
            <TradeReferencesCard application={application} />
          </Paper>
        </Slide>
        <Slide
          direction="right"
          in={tab === 3}
          mountOnEnter
          unmountOnExit
          exit={false}
        >
          <Paper>
            <SkippedMessage
              label={PAGE_LABEL_PERSONAL_GUARANTY}
              application={application}
            />
            <CreditApplicationDetailsPersonalGuaranty
              application={application}
              refreshApplication={refetchApplication}
            />
          </Paper>
        </Slide>
        <Slide
          direction="right"
          in={tab === 4}
          mountOnEnter
          unmountOnExit
          exit={false}
        >
          <Paper>{renderFiles}</Paper>
        </Slide>
        <Slide
          direction="right"
          in={tab === 5}
          mountOnEnter
          unmountOnExit
          exit={false}
        >
          <Paper>
            <Card>
              <CreditApplicationBusinessRegistries
                application={application}
                setOpenCompanyDataDialogs={setOpenCompanyDataDialogs}
              />
              <Divider />
              <UploadedCreditReport application={application} />
            </Card>
          </Paper>
        </Slide>
        <Slide
          direction="right"
          in={tab === 6}
          mountOnEnter
          unmountOnExit
          exit={false}
        >
          <Paper>
            <CreditApplicationBankReference
              application={application}
              refetchApplication={refetchApplication}
            />
          </Paper>
        </Slide>

        <PDFOrImageViewerDialog
          fileId={viewDocument}
          onClose={() => {
            setViewDocument(undefined)
          }}
        />
        {addSignerDialog && (
          <AddSignerDialog
            applicationId={applicationId}
            onClose={() => {
              setAddSignerDialog(false)
              refetchApplication()
            }}
          />
        )}

        <Dialog
          open={open}
          onClose={() => {
            setOpen(false)
          }}
        >
          <DialogTitle>Search for contractor license</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="License"
              type="text"
              fullWidth
              variant="standard"
              value={contractor}
              onChange={(e) => {
                setContractor(e.target.value)
              }}
            />
          </DialogContent>
          {contractorData && (
            <DialogContentText>
              <Typography>Business: {contractorData?.bus || ""}</Typography>
              <Typography>Entity: {contractorData?.entity || ""}</Typography>
              <Typography>
                Issue Date: {contractorData?.issDate || ""}
              </Typography>
              <Typography>Expiry: {contractorData?.expDate || ""}</Typography>
            </DialogContentText>
          )}
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false)
                setContractor("")
              }}
            >
              Close
            </Button>
            <LoadingButton
              onClick={() => {
                refetch()
              }}
              loading={isLoading}
            >
              Search
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </>
    </Card>
  )
}
